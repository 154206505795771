define('frontend-cp/components/side-conversations-panel/message-timeline/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',

    messages: [],

    sideConversationCreatorEmail: computed('messages.[]', function () {
      return this.get('messages.length') > 0 ? this.get('messages.0.message.creator.emails.0.email') : '';
    }),

    messagesWithRecipients: computed('messages.@each.message.recipients', function () {
      var _this = this;

      return this.get('messages').map(function (messageItem) {
        var toRecipients = messageItem.message.recipients.filter(function (recipient) {
          return recipient.type === 'TO';
        });
        var ccRecipients = messageItem.message.recipients.filter(function (recipient) {
          return recipient.type === 'CC';
        });
        var newMessageItem = Object.assign({}, messageItem);
        if (toRecipients.length === 0) {
          toRecipients.push({ identity: { email: _this.get('sideConversationCreatorEmail') } });
        }
        newMessageItem.toRecipients = toRecipients;
        newMessageItem.ccRecipients = ccRecipients;
        if (newMessageItem.message.attachments) {
          newMessageItem.message.attachments = newMessageItem.message.attachments.map(function (attachment) {
            return Object.assign({}, attachment, {
              urlDownload: attachment.url_download
            });
          });
        }
        return newMessageItem;
      });
    })
  });
});