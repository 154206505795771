define('frontend-cp/components/article-form/component', ['exports', 'frontend-cp/config/environment', 'ember-concurrency', 'frontend-cp/utils/is-internal-tag'], function (exports, _environment, _emberConcurrency, _isInternalTag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  var computed = Ember.computed;
  var observer = Ember.observer;
  var Promise = Ember.RSVP.Promise;
  exports.default = Component.extend({
    article: null,
    categoriesTree: [],
    statuses: ['DRAFT', 'PUBLISHED'],
    headingTitle: 'New Article',
    onCancel: function onCancel() {},
    onSave: function onSave() {},

    articleEvents: service(),
    sessionService: service('session'),
    store: service(),
    router: service(),
    tagsService: service('tags'),
    notification: service(),
    locale: service(),
    i18n: service(),

    isSaving: false,
    originalArticle: null,
    selectedLocale: null,
    sectionSearchEnabled: true,

    articleTitleChange: observer('currentTitle', function () {
      if (this.get('article.errors.titles')) {
        this.set('article.errors.titles', null);
      }
    }),

    currentTitle: computed('article.titles.@each.translation', 'selectedLocale', function () {
      var selectedLocale = this.get('selectedLocale');
      var articleTitles = this.get('article.titles');
      var index = articleTitles.findIndex(function (t) {
        return t.locale === selectedLocale;
      });
      if (index > -1) {
        return articleTitles[index].translation;
      }
      return '';
    }),

    currentLocaleContent: computed('article.contents.@each.translation', 'selectedLocale', function () {
      var selectedLocale = this.get('selectedLocale');
      var articleContents = this.get('article.contents');
      var index = articleContents.findIndex(function (c) {
        return c.locale === selectedLocale;
      });
      if (index > -1) {
        return articleContents[index].translation;
      }
      return '';
    }),

    contentChangeObserver: observer('currentLocaleContent', function () {
      if (this.get('article.errors.contents')) {
        this.set('article.errors.contents', null);
      }
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      document.querySelector('form').addEventListener('keydown', function (event) {
        if (event.key === 'Enter' && (!event.target || event.target.type !== 'textarea')) {
          event.preventDefault();
        }
      });
    },


    onCancelAttachment: function onCancelAttachment() {},
    attachedFiles: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.get('fetchCategories').perform();
      var article = this.get('article');
      var defaultLocale = this.get('locale.accountDefaultLocaleCode');
      this.set('selectedLocale', defaultLocale);
      this.set('originalArticle', JSON.parse(JSON.stringify(article)));
      this.set('isSaving', false);
      if (!article.id) {
        var currentUser = this.get('sessionService.user');
        this.set('article.author', {
          id: currentUser.get('id'),
          fullName: currentUser.get('fullName')
        });
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.resetArticle();
    },
    resetArticle: function resetArticle() {
      var originalArticle = this.get('originalArticle');
      this.set('article', JSON.parse(JSON.stringify(originalArticle)));
    },


    froalaParams: computed('plugins', function () {
      var lineHeight = 20;
      var padding = 24;
      return {
        heightMin: 18 * lineHeight + padding,
        requestHeaders: { 'X-CSRF-Token': this.get('sessionService.csrfToken') },
        key: _environment.default.froalaEditor.key,
        imageUploadURL: '/api/v1/media?include=*',
        imageUploadParam: 'files',
        imageDefaultDisplay: 'inline',
        imageEditButtons: ['imageReplace', 'imageAlign', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageAlt', 'imageSize'],
        imageDefaultWidth: 0,
        tableEditButtons: ['tableHeader', 'tableRemove', '|', 'tableRows', 'tableColumns', 'tableStyle', '-', 'tableCells', 'tableCellBackground', 'tableCellVerticalAlign', 'tableCellHorizontalAlign'],
        tableStyles: {
          'fr-alternate-rows': 'Alternate Rows'
        },
        toolbarButtons: ['paragraphFormat', '|', 'fontSize', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'color', 'emoticons', '|', 'align', '|', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '|', 'insertLink', 'insertImage', 'insertTable', 'insertHR', '|', 'undo', 'redo', 'clearFormatting', 'html', 'fullscreen'],
        toolbarButtonsMD: ['paragraphFormat', '|', 'fontSize', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'color', 'emoticons', '|', 'align', '|', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '|', 'insertLink', 'insertImage', 'insertTable', 'insertHR', '|', 'undo', 'redo', 'clearFormatting', 'html', 'fullscreen'],
        toolbarSticky: true,
        toolbarStickyOffset: 0,
        htmlRemoveTags: ['style'],
        linkInsertButtons: [],
        linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
        pluginsEnabled: this.get('plugins'),
        zIndex: 60,
        classNames: ['froala-editor-container-full']
      };
    }),

    plugins: ['align', 'link', 'lists', 'paragraphFormat', 'quote', 'url', 'image', 'fontSize', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'emoticons', 'fontFamily', 'fullscreen', 'table'],

    // Pre-selects first section of first category in the dropdown
    preSelectSection: function preSelectSection() {
      var firstSectionId = this.get('categoriesTree.firstObject.children.firstObject.id');
      this.set('article.section', firstSectionId);
    },


    fetchCategories: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var store, sections, tree;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              store = this.get('store');
              _context.next = 3;
              return store.query('section', {});

            case 3:
              sections = _context.sent;
              tree = [];


              sections.forEach(function (section) {
                var category = section.get('category');
                var sectionNode = {
                  id: section.get('id'),
                  value: section.get('title')
                };
                var categoryNode = tree.findBy('value', category.get('title'));

                if (!categoryNode) {
                  categoryNode = {
                    value: category.get('title'),
                    children: []
                  };

                  tree.push(categoryNode);
                }

                categoryNode.children.push(sectionNode);
              });

              // Set the tree with all loaded sections
              this.set('categoriesTree', tree);

              // If no section is set for the article, pre-select the first one
              if (!this.get('article.section')) {
                this.preSelectSection();
              } else {
                // If this is an existing article, check if it has a section
                this.loadArticleSection();
              }

            case 8:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    search: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(term) {
      var store, sections, results;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return (0, _emberConcurrency.timeout)(300);

            case 2:
              if (!(!term || term.trim() === '')) {
                _context2.next = 4;
                break;
              }

              return _context2.abrupt('return', []);

            case 4:

              // Call the sections API with the search term
              store = this.get('store');
              _context2.next = 7;
              return store.query('section', { search: term });

            case 7:
              sections = _context2.sent;


              // Create a flattened list of sections with their categories
              results = [];

              sections.forEach(function (section) {
                var category = section.get('category');
                var result = {
                  id: section.get('id'),
                  value: category.get('title') + ' / ' + section.get('title'),
                  categoryName: category.get('title'),
                  sectionName: section.get('title')
                };
                results.push(result);
              });

              return _context2.abrupt('return', results);

            case 11:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).restartable(),

    visibleTags: computed('article.tags.@each.name', function () {
      return this.get('article.tags').filter(function (tag) {
        return !(0, _isInternalTag.default)(tag);
      });
    }),

    suggestTags: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3(searchTerm) {
      var tags, result;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.next = 2;
              return (0, _emberConcurrency.timeout)(300);

            case 2:
              _context3.next = 4;
              return this.get('store').query('tag', { name: searchTerm });

            case 4:
              tags = _context3.sent;
              result = tags.mapBy('name').map(function (name) {
                return { name: name };
              });
              return _context3.abrupt('return', result);

            case 7:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    })).restartable(),

    updateCategoriesTreeWithSection: function updateCategoriesTreeWithSection(sectionInfo) {
      var setAsSelected = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (!sectionInfo) {
        return;
      }

      var tree = this.get('categoriesTree') || [];

      var id = sectionInfo.id,
          categoryName = sectionInfo.categoryName,
          sectionName = sectionInfo.sectionName;

      // Check if the category already exists in the tree

      var categoryNode = tree.findBy('value', categoryName);

      // If category doesn't exist, create it
      if (!categoryNode) {
        categoryNode = {
          value: categoryName,
          children: []
        };
        tree.push(categoryNode);
      }

      // Check if the section already exists in the category
      var sectionNode = categoryNode.children.findBy('id', id);

      // If section doesn't exist, add it
      if (!sectionNode) {
        sectionNode = {
          id: id,
          value: sectionName
        };
        categoryNode.children.push(sectionNode);
      }

      // Update the tree
      this.set('categoriesTree', [].concat(_toConsumableArray(tree)));

      // If setAsSelected is true, also set this section as the selected one
      if (setAsSelected && id) {
        this.set('article.section', id);
      }
    },
    loadArticleSection: function loadArticleSection() {
      var _this = this;

      var article = this.get('originalArticle');
      var sectionId = article ? article.section : null;

      if (!sectionId) {
        return;
      }

      // Check if the section exists in the tree
      var sectionExists = false;
      var tree = this.get('categoriesTree') || [];

      tree.forEach(function (category) {
        var foundSection = category.children.findBy('id', sectionId);
        if (foundSection) {
          sectionExists = true;
        }
      });

      // If the section doesn't exist in the tree, fetch it directly
      if (sectionExists) {
        return;
      }
      var store = this.get('store');
      store.findRecord('section', sectionId).then(function (section) {
        if (!section) {
          return;
        }

        var category = section.get('category');

        // Create section info
        var sectionInfo = {
          id: section.get('id'),
          value: section.get('title'),
          categoryName: category.get('title'),
          sectionName: section.get('title')
        };

        // Add to the tree and set as selected in one operation
        _this.updateCategoriesTreeWithSection(sectionInfo, true);
      });
    },


    actions: {
      updateArticleContent: function updateArticleContent(content) {
        var _this2 = this;

        var selectedLocale = this.get('selectedLocale');
        var defaultLocale = this.get('locale.accountDefaultLocaleCode');
        var articleContents = this.get('article.contents');
        var articleTitles = this.get('article.titles');

        var contentIndex = articleContents.findIndex(function (c) {
          return c.locale === selectedLocale;
        });
        var titleIndex = articleTitles.findIndex(function (t) {
          return t.locale === selectedLocale;
        });

        var removeTitleAndContent = function removeTitleAndContent() {
          if (titleIndex > -1) articleTitles.removeAt(titleIndex);
          if (contentIndex > -1) articleContents.removeAt(contentIndex);
        };

        var updateContent = function updateContent() {
          if (contentIndex > -1) {
            _this2.set('article.contents.' + contentIndex + '.translation', content);
          } else {
            articleContents.pushObject({ locale: selectedLocale, translation: content });
          }
        };

        var ensureTitleExists = function ensureTitleExists() {
          if (titleIndex < 0) {
            articleTitles.pushObject({ locale: selectedLocale, translation: '' });
          }
        };

        if (!content && selectedLocale !== defaultLocale) {
          var titleEmpty = titleIndex > -1 && !articleTitles[titleIndex].translation || titleIndex < 0;
          if (titleEmpty) {
            removeTitleAndContent();
          } else {
            updateContent();
          }
        } else {
          updateContent();
        }

        if (content) {
          ensureTitleExists();
        }
      },
      updateArticleTitle: function updateArticleTitle(title) {
        var _this3 = this;

        var selectedLocale = this.get('selectedLocale');
        var defaultLocale = this.get('locale.accountDefaultLocaleCode');
        var articleTitles = this.get('article.titles');
        var articleContents = this.get('article.contents');

        var titleIndex = articleTitles.findIndex(function (t) {
          return t.locale === selectedLocale;
        });
        var contentIndex = articleContents.findIndex(function (c) {
          return c.locale === selectedLocale;
        });

        var removeTitleAndContent = function removeTitleAndContent() {
          if (titleIndex > -1) articleTitles.removeAt(titleIndex);
          if (contentIndex > -1) articleContents.removeAt(contentIndex);
        };

        var updateTitle = function updateTitle() {
          if (titleIndex > -1) {
            _this3.set('article.titles.' + titleIndex + '.translation', title);
          } else {
            articleTitles.pushObject({ locale: selectedLocale, translation: title });
          }
        };

        var ensureContentExists = function ensureContentExists() {
          if (contentIndex < 0) {
            articleContents.pushObject({ locale: selectedLocale, translation: '' });
          }
        };

        if (!title && selectedLocale !== defaultLocale) {
          var contentEmpty = contentIndex > -1 && !articleContents[contentIndex].translation || contentIndex < 0;
          if (contentEmpty) {
            removeTitleAndContent();
          } else {
            updateTitle();
          }
        } else {
          updateTitle();
        }

        if (title) {
          ensureContentExists();
        }
      },
      updateSelectedLocale: function updateSelectedLocale(newLocale) {
        this.set('selectedLocale', newLocale.code);
      },
      changeSectionValue: function changeSectionValue(value) {
        // If this is a search result (has 'id' property directly)
        if (value && value.id && value.categoryName && value.sectionName) {
          // Add the selected section to the categoriesTree and set it as selected
          this.updateCategoriesTreeWithSection(value, true);
        } else {
          this.set('article.section', get(value, 'id'));
        }
      },
      save: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee5() {
          var _this4 = this;

          var article, errors, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, title, localeLanguage, _iteratorNormalCompletion2, _didIteratorError2, _iteratorError2, _iterator2, _step2, content, _localeLanguage, anyFileUploading, newArticle, store, articleAdapter, originalArticle, removedAttachments, createdArticle;

          return regeneratorRuntime.wrap(function _callee5$(_context5) {
            while (1) {
              switch (_context5.prev = _context5.next) {
                case 0:
                  _context5.prev = 0;

                  this.set('isSaving', true);
                  article = this.get('article');
                  errors = {};
                  _iteratorNormalCompletion = true;
                  _didIteratorError = false;
                  _iteratorError = undefined;
                  _context5.prev = 7;


                  for (_iterator = article.titles[Symbol.iterator](); !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    title = _step.value;

                    if (!title.translation) {
                      if (!errors.titles) {
                        errors.titles = [];
                      }
                      localeLanguage = this.get('locale').getLocaleLanguage(title.locale);

                      errors.titles.push({ message: this.get('i18n').t('admin.knowledgebase.errors.title_required', { localeLanguage: localeLanguage }) });
                    }
                  }

                  _context5.next = 15;
                  break;

                case 11:
                  _context5.prev = 11;
                  _context5.t0 = _context5['catch'](7);
                  _didIteratorError = true;
                  _iteratorError = _context5.t0;

                case 15:
                  _context5.prev = 15;
                  _context5.prev = 16;

                  if (!_iteratorNormalCompletion && _iterator.return) {
                    _iterator.return();
                  }

                case 18:
                  _context5.prev = 18;

                  if (!_didIteratorError) {
                    _context5.next = 21;
                    break;
                  }

                  throw _iteratorError;

                case 21:
                  return _context5.finish(18);

                case 22:
                  return _context5.finish(15);

                case 23:
                  _iteratorNormalCompletion2 = true;
                  _didIteratorError2 = false;
                  _iteratorError2 = undefined;
                  _context5.prev = 26;
                  for (_iterator2 = article.contents[Symbol.iterator](); !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                    content = _step2.value;

                    if (!content.translation) {
                      if (!errors.contents) {
                        errors.contents = [];
                      }
                      _localeLanguage = this.get('locale').getLocaleLanguage(content.locale);

                      errors.contents.push({ message: this.get('i18n').t('admin.knowledgebase.errors.content_required', { localeLanguage: _localeLanguage }) });
                    }
                  }

                  _context5.next = 34;
                  break;

                case 30:
                  _context5.prev = 30;
                  _context5.t1 = _context5['catch'](26);
                  _didIteratorError2 = true;
                  _iteratorError2 = _context5.t1;

                case 34:
                  _context5.prev = 34;
                  _context5.prev = 35;

                  if (!_iteratorNormalCompletion2 && _iterator2.return) {
                    _iterator2.return();
                  }

                case 37:
                  _context5.prev = 37;

                  if (!_didIteratorError2) {
                    _context5.next = 40;
                    break;
                  }

                  throw _iteratorError2;

                case 40:
                  return _context5.finish(37);

                case 41:
                  return _context5.finish(34);

                case 42:
                  if (article.attachments.length > 0) {
                    anyFileUploading = article.attachments.find(function (a) {
                      return a.isUploading;
                    });

                    if (anyFileUploading) {
                      errors.attachments = [{ message: this.get('i18n').t('admin.knowledgebase.errors.wait_for_upload') }];
                    }
                  }

                  if (!(Object.keys(errors).length > 0)) {
                    _context5.next = 46;
                    break;
                  }

                  this.set('article.errors', errors);
                  return _context5.abrupt('return');

                case 46:
                  newArticle = {
                    titles: article.titles,
                    contents: article.contents,
                    status: article.status,
                    section_id: article.section,
                    author_id: article.author.id,
                    keywords: article.searchKeywords,
                    tags: article.tags ? article.tags.map(function (t) {
                      return t.name;
                    }).join(',') : '',
                    files: article.attachments,
                    is_featured: !!article.pinArticle,
                    allow_comments: !!article.allowComments,
                    attachment_file_ids: article.attachments.map(function (file) {
                      return file.attachmentId;
                    }).join(',')
                  };
                  store = this.get('store');
                  articleAdapter = store.adapterFor('article');

                  if (!article.id) {
                    _context5.next = 62;
                    break;
                  }

                  originalArticle = this.get('originalArticle');
                  removedAttachments = originalArticle.attachments.filter(function (originalAttachment) {
                    return !article.attachments.some(function (currentAttachment) {
                      return currentAttachment.name === originalAttachment.name && currentAttachment.size === originalAttachment.size;
                    });
                  });

                  if (!(removedAttachments.length > 0)) {
                    _context5.next = 55;
                    break;
                  }

                  _context5.next = 55;
                  return Promise.all(removedAttachments.map(function () {
                    var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4(attachment) {
                      return regeneratorRuntime.wrap(function _callee4$(_context4) {
                        while (1) {
                          switch (_context4.prev = _context4.next) {
                            case 0:
                              return _context4.abrupt('return', articleAdapter.removeAttachment(article.id, attachment.id));

                            case 1:
                            case 'end':
                              return _context4.stop();
                          }
                        }
                      }, _callee4, _this4);
                    }));

                    return function (_x2) {
                      return _ref2.apply(this, arguments);
                    };
                  }()));

                case 55:
                  _context5.next = 57;
                  return articleAdapter.updateArticle(article.id, newArticle);

                case 57:
                  this.get('articleEvents').articleUpdated();
                  this.set('originalArticle', JSON.parse(JSON.stringify(article)));
                  this.onSave();
                  _context5.next = 68;
                  break;

                case 62:
                  _context5.next = 64;
                  return articleAdapter.addArticle(newArticle);

                case 64:
                  createdArticle = _context5.sent;
                  _context5.next = 67;
                  return this.get('router').transitionTo('session.agent.knowledgebase.article-view', createdArticle.id);

                case 67:
                  this.get('articleEvents').articleCreated();

                case 68:
                  _context5.next = 73;
                  break;

                case 70:
                  _context5.prev = 70;
                  _context5.t2 = _context5['catch'](0);

                  this.get('notification').error(this.get('i18n').t('generic.generic_error'));

                case 73:
                  _context5.prev = 73;

                  this.set('isSaving', false);
                  return _context5.finish(73);

                case 76:
                case 'end':
                  return _context5.stop();
              }
            }
          }, _callee5, this, [[0, 70, 73, 76], [7, 11, 15, 23], [16,, 18, 22], [26, 30, 34, 42], [35,, 37, 41]]);
        }));

        function save() {
          return _ref.apply(this, arguments);
        }

        return save;
      }(),
      addTag: function addTag(tag) {
        var article = this.get('article');
        var newTags = [].concat(_toConsumableArray(article.tags));
        newTags.push(tag);
        this.set('article.tags', newTags);
      },
      removeTag: function removeTag(tag) {
        var article = this.get('article');
        article.tags.removeObject(tag);
        this.set('article.tags', article.tags);
      },
      onUploadAttachments: function onUploadAttachments(files, overwrite) {
        var _this5 = this;

        if (this.get('article.errors.attachments')) {
          this.set('article.errors.attachments', null);
        }
        var article = this.get('article');
        if (overwrite) {
          this.set('article.attachments', files);
        } else {
          var combinedAttachments = [].concat(_toConsumableArray(article.attachments));

          var _loop = function _loop(file) {
            if (file.isUploading) {
              combinedAttachments.push(file);
            } else {
              var indexToUpdate = combinedAttachments.findIndex(function (f) {
                return f.name === file.name && f.size === file.size;
              });
              if (indexToUpdate > -1) {
                if (file.status === 'ERROR') {
                  if (!_this5.get('article.errors')) {
                    _this5.set('article.errors', {});
                  }
                  if (file.error === 'TOO_LARGE') {
                    _this5.set('article.errors.attachments', [{ message: _this5.get('i18n').t('admin.knowledgebase.errors.file_too_large') }]);
                  } else {
                    _this5.set('article.errors.attachments', [{ message: _this5.get('i18n').t('admin.knowledgebase.errors.file_upload_error') }]);
                  }
                  combinedAttachments.splice(indexToUpdate, 1);
                } else {
                  combinedAttachments[indexToUpdate] = file;
                }
              }
            }
          };

          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = files[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var file = _step3.value;

              _loop(file);
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }

          this.set('article.attachments', combinedAttachments);
        }
      },
      handleImageUpload: function handleImageUpload(component, response) {
        // Overriding the default image upload handler to fetch the image URL from the response
        // https://wysiwyg-editor.froala.help/hc/en-us/articles/115000593909-How-can-I-use-a-different-image-response
        var editor = component.get('editor');
        var parsedResponse = JSON.parse(response);
        var imageUrl = new URL(parsedResponse.data[0].attachment.url.replace('/media/', '/base/media/'));
        editor.image.insert(imageUrl.pathname, false, parsedResponse, editor.image.get(), response);
        return false;
      },
      handleImageToolbarOpen: function handleImageToolbarOpen(component) {
        // Add a class explicitly to the image edit toolbar to make it visible
        component.get('editor').popups.get('image.edit').addClass('image-edit-toolbar-visible');
      }
    }
  });
});