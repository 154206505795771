define('frontend-cp/components/ko-select/drill-down/component', ['exports', 'npm:lodash', 'ember-sanitize/utils/sanitize', 'ember-concurrency'], function (exports, _npmLodash, _sanitize, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.buildTreeFromList = buildTreeFromList;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var on = Ember.on;
  var assign = Ember.assign;
  var map = _npmLodash.default.map,
      groupBy = _npmLodash.default.groupBy,
      partition = _npmLodash.default.partition;
  exports.default = Component.extend({
    tagName: '',

    // Attributes
    options: [],
    value: null,
    onValueChange: null,
    onTraversingItems: null,
    emptyLabel: null,
    hasEmptyOption: false,
    extra: null,
    triggerComponent: null,
    triggerClass: null,
    placeholder: null,
    searchPlaceholder: null,
    searchEnabled: false,
    searchField: 'value',
    isDisabled: false,
    showFullPathOnLeaves: false,
    dropdownClass: null,
    renderInPlace: false,
    matchTriggerWidth: true,
    tabIndex: 0,
    verticalPosition: 'auto',
    horizontalPosition: 'auto',
    noSanitize: false,

    // State
    currentPath: null,

    resetState: on('init', 'didReceiveAttrs', function () {
      this.set('currentPath', []);
    }),

    sanitizedOptions: computed('options.[]', function () {
      var _this = this;

      return this.get('options').map(function (_ref) {
        var id = _ref.id,
            value = _ref.value,
            object = _ref.object,
            children = _ref.children;

        if (value && typeof value === 'string' && !_this.get('noSanitize')) {
          value = (0, _sanitize.sanitize)(value);
        }
        return { id: id, value: value, object: object, children: children };
      });
    }),

    // CPs
    currentHierarchyLevel: computed('sanitizedOptions.[]', 'currentPath.[]', 'showFullPathOnLeaves', function () {
      var showFullPathOnLeaves = this.get('showFullPathOnLeaves');

      var find = function find(item, pathLeft, pathTraversed) {
        if (pathLeft.length === 0) {
          var children = item.children.map(function (child) {
            return assign({}, child, {
              value: showFullPathOnLeaves ? pathTraversed.concat(child.value).join(' / ') : child.value,
              object: child.object,
              levelValue: get(child, 'value.text') || get(child, 'value')
            });
          });

          if (item.id) {
            return [{
              value: showFullPathOnLeaves ? pathTraversed.join(' / ') : item.value,
              object: item.object,
              levelValue: get(item, 'value.text') || get(item, 'value'),
              id: item.id
            }].concat(_toConsumableArray(children));
          } else {
            return children;
          }
        } else {
          return find(item.children.find(function (option) {
            return get(option, 'value') === pathLeft[0];
          }), pathLeft.slice(1), [].concat(_toConsumableArray(pathTraversed), [pathLeft[0]]));
        }
      };

      var items = find({ children: this.get('sanitizedOptions') }, this.get('currentPath'), []);
      if (this.get('hasEmptyOption') && this.get('currentPath').length === 0) {
        items.unshift({
          type: 'empty',
          value: this.get('emptyLabel') || '-'
        });
      }

      if (this.get('currentPath').length > 0) {
        items.unshift({
          type: 'back',
          value: 'Back'
        });
      }
      return items;
    }),

    flatOptions: computed('sanitizedOptions.[]', function () {
      var flatten = function flatten(options) {
        return _npmLodash.default.flatten(options.map(function (option) {
          var children = flatten(option.children || []).map(function (item) {
            return { id: item.id, object: item.object, value: option.value + ' / ' + item.value };
          });
          if (option.id) {
            return [{ id: option.id, object: option.object, value: option.value }].concat(_toConsumableArray(children));
          } else {
            return children;
          }
        }));
      };

      return flatten(this.get('sanitizedOptions'));
    }),

    // We can't listen for deeply nested changes in a data structure which can go
    // infinitely deep. Thus the implicit contract between this and parent is
    // immutability of `options`.
    formattedValue: computed('sanitizedOptions.[]', 'value', function () {
      var value = this.get('value');
      if (!value) {
        return this.get('emptyLabel') || '-';
      }

      var find = function find(options) {
        var item = options.find(function (option) {
          return get(option, 'id') === value;
        });
        if (item) {
          return [get(item, 'value')];
        } else {
          return options.filter(function (option) {
            return get(option, 'children');
          }).map(function (option) {
            return [get(option, 'value')].concat(_toConsumableArray(find(get(option, 'children'))));
          }).find(function (option) {
            return option.length > 1;
          }) || [];
        }
      };

      return find(this.get('sanitizedOptions')).join(' / ');
    }),

    computedExtra: computed('extra', 'formattedValue', function () {
      return assign({}, this.get('extra') || {}, {
        labelPath: 'value',
        formattedValue: this.get('formattedValue')
      });
    }),

    search: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(term) {
      var termLowerCase, matches;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return (0, _emberConcurrency.timeout)(300);

            case 2:
              termLowerCase = term.toLowerCase();

              matches = function matches(option) {
                return option.value.toLowerCase().indexOf(termLowerCase) !== -1;
              };

              return _context.abrupt('return', this.get('flatOptions').filter(matches));

            case 5:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),

    actions: {
      handleMouseDown: function handleMouseDown(e) {
        e.preventDefault();
        e.stopPropagation();
      },
      selectItem: function selectItem(item, dropdown) {
        if (this.get('onTraversingItems')) {
          this.get('onTraversingItems')(item);
        }

        if (get(item, 'children.length')) {
          this.get('currentPath').pushObject(get(item, 'levelValue'));
        } else if (get(item, 'id')) {
          dropdown.actions.close();
          this.get('onValueChange')(item);
        } else if (get(item, 'type') === 'empty') {
          dropdown.actions.close();
          this.get('onValueChange')(null);
        } else if (get(item, 'type') === 'back') {
          this.get('currentPath').popObject();
        }
      },
      resetState: function resetState() {
        this.resetState();
        if (this.get('onTraversingItems')) {
          this.get('onTraversingItems')(null);
        }
      }
    }
  });
  function buildTreeFromList(list, cb) {
    var splitItems = list.map(cb).map(function (item) {
      return assign({}, item, {
        value: (item.value || '').split(/\\/).map(function (value) {
          return value.trim();
        })
      });
    });

    var group = function group(items) {
      return map(groupBy(items, function (item) {
        return item.value[0];
      }), function (items, label) {
        var _partition = partition(items, function (item) {
          return item.value.length > 1;
        }),
            _partition2 = _slicedToArray(_partition, 2),
            children = _partition2[0],
            _partition2$ = _slicedToArray(_partition2[1], 1),
            current = _partition2$[0];

        return {
          id: current ? current.id : null,
          value: label.trim(),
          children: children && children.length ? group(children.map(function (item) {
            return {
              id: item.id,
              value: item.value.slice(1),
              object: item.object
            };
          })) : null,
          object: current ? current.object : null
        };
      });
    };

    return group(splitItems);
  }
});