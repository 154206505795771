define('frontend-cp/state-managers/case', ['exports', 'ember-service/inject', 'ember-concurrency', 'npm:lodash', 'npm:lodash/cloneDeep', 'frontend-cp/sanitizers/server-html-content', 'ember-sanitize/utils/sanitize', 'npm:uuid/v4', 'frontend-cp/utils/object', 'npm:html-to-text', 'frontend-cp/lib/html-to-text', 'frontend-cp/services/virtual-model', 'frontend-cp/lib/edited-custom-fields', 'frontend-cp/lib/upload-file', 'frontend-cp/lib/at-mentions'], function (exports, _inject, _emberConcurrency, _npmLodash, _cloneDeep, _serverHtmlContent, _sanitize, _v, _object, _npmHtmlToText, _htmlToText, _virtualModel, _editedCustomFields, _uploadFile, _atMentions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.OP_STATE_FAILED = exports.OP_STATE_SENDING = exports.OP_STATE_WAITING = undefined;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var bool = Ember.computed.bool;
  var or = Ember.computed.or;
  var notEmpty = Ember.computed.notEmpty;
  var isEmpty = Ember.isEmpty;
  var EmberObject = Ember.Object;
  var observer = Ember.observer;
  var computed = Ember.computed;
  var Promise = Ember.RSVP.Promise;
  var scheduleOnce = Ember.run.scheduleOnce;
  var assign = Ember.assign;
  var service = Ember.inject.service;
  var get = Ember.get;
  var set = Ember.set;
  var Evented = Ember.Evented;
  var typeOf = Ember.typeOf;
  var OP_STATE_WAITING = exports.OP_STATE_WAITING = 'client-waiting';
  var OP_STATE_SENDING = exports.OP_STATE_SENDING = 'client-sending';
  var OP_STATE_FAILED = exports.OP_STATE_FAILED = 'client-failed';

  var caseSchema = (0, _virtualModel.model)('case', {
    subject: (0, _virtualModel.attr)(),
    assignedTeam: (0, _virtualModel.attr)(),
    assignedAgent: (0, _virtualModel.attr)(),
    requester: (0, _virtualModel.attr)(),
    status: (0, _virtualModel.attr)(),
    caseType: (0, _virtualModel.attr)(),
    priority: (0, _virtualModel.attr)(),
    form: (0, _virtualModel.attr)(),
    identity: (0, _virtualModel.attr)(),
    customFields: (0, _virtualModel.many)((0, _virtualModel.fragment)('case-field-value', {
      field: (0, _virtualModel.attr)(),
      value: (0, _virtualModel.attr)()
    }))
  });

  var closeSchema = (0, _virtualModel.model)('case', {
    status: (0, _virtualModel.attr)()
  });

  // TODO unify
  var convertErrorsToMap = function convertErrorsToMap(errors) {
    return (errors || []).filter(function (error) {
      return error.parameter;
    }).reduce(function (errorMap, error) {
      errorMap.set(error.parameter, true);
      return errorMap;
    }, EmberObject.create({}));
  };

  var cleanupAttachments = function cleanupAttachments(attachments) {
    attachments.removeObjects(attachments.filter(function (attachment) {
      return !isEmpty(attachment.get('error'));
    }));
    return attachments;
  };

  var getAssigneeFromMacro = function getAssigneeFromMacro(user, macro) {
    switch (macro.get('assigneeType')) {
      case 'UNASSIGNED':
        return [null, null];
      case 'CURRENT_AGENT':
        return [user.get('teams.firstObject'), user];
      case 'TEAM':
        return [macro.get('assignedTeam'), macro.get('assignedAgent')];
      case 'AGENT':
        return [macro.get('assignedTeam'), macro.get('assignedAgent')];
    }
  };

  var formatPostForSending = function formatPostForSending(content, channel) {
    if (channel.get('isChannelTypeMailbox') || channel.get('channelType') === 'NOTE') {
      return (0, _htmlToText.formatHTMLForSendingAsHTML)(content);
    } else {
      return (0, _htmlToText.formatHTMLForSendingAsText)(content);
    }
  };

  exports.default = EmberObject.extend(Evented, {
    launchDarkly: (0, _inject.default)(),

    tab: null,
    model: null,
    publicChannelId: null,
    isNote: false,
    postContent: '',
    subject: '',
    attachedPostFiles: null,
    loadingTop: false,
    loadingBottom: false,
    bottomPostsAvailable: false,
    topPostsAvailable: true,
    posts: null,
    errorMap: null,
    inReplyTo: null,
    suggestedPeople: null,
    suggestedPeopleTotal: 0,
    suggestedPeopleLoading: false,
    editedCase: null,
    editedTags: null,
    replyOptions: null,
    propertiesChangeViaKRE: null,
    updateLog: null,
    viewingUsers: null,
    isCCActive: false,
    hasCCInput: false,
    requester: null,
    timerValue: null,
    isBillable: null,
    noteDestination: null,

    confirmation: service(),
    mergeConversation: service(),
    i18n: service(),
    metrics: service(),
    macro: service(),
    notification: service(),
    timeline: service(),
    store: service(),
    tabStore: service(),
    tagService: service('tags'),
    apiAdapter: service(),
    virtualModel: service(),
    session: service(),
    serverClock: service(),

    init: function init() {
      var _this = this;

      var launchDarkly = this.get('launchDarkly');

      this._super.apply(this, arguments);
      this.setProperties({
        posts: [],
        replyOptions: EmberObject.create({
          cc: [],
          type: null
        }),
        viewingUsers: []
      });
      this.initEdits();

      var savedState = (0, _object.jsonToObject)(this.get('tab').state.case);
      var requesterId = savedState.requesterId,
          subject = savedState.subject;

      // attachments should be re-created as correct UploadFile objects

      if (savedState.attachedPostFiles) {
        savedState.attachedPostFiles = savedState.attachedPostFiles.map(function (attachment) {
          return _uploadFile.default.create(attachment);
        });
      }

      this.setProperties(savedState);

      if (launchDarkly.get('release-cc-list-improvements')) {
        // do nothing
      } else {
        if (this.get('replyOptions.cc.length')) {
          this.set('isCCActive', true);
        }
      }

      if (subject) {
        this.set('editedCase.subject', subject);
      }

      if (requesterId) {
        this.get('loadRequester').perform(requesterId).then(function () {
          return _this.persistTabState();
        });
      } else {
        this.persistTabState();
      }
      this.setParticipantsAsCCs();
    },


    loadRequester: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee(requesterId) {
      var store, user;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              store = this.get('store');
              _context.next = 3;
              return store.findRecord('user', requesterId);

            case 3:
              user = _context.sent;


              this.set('editedCase.requester', user);

            case 5:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })),

    publicChannel: computed('publicChannelId', 'model.replyChannels.[]', function () {
      return this.get('model.replyChannels').findBy('id', this.get('publicChannelId'));
    }),

    channel: computed('publicChannel', 'isNote', 'model.replyChannels.[]', function () {
      if (this.get('isNote')) {
        return this.get('model.replyChannels').findBy('channelType', 'NOTE');
      } else {
        return this.get('publicChannel');
      }
    }),

    inReplyToPost: computed('inReplyTo.id', function () {
      var id = this.get('inReplyTo.id');
      if (id) {
        return this.get('store').find('post', id);
      } else {
        return null;
      }
    }),

    isContentEdited: computed('postContent', function () {
      var postContent = this.get('postContent');
      var trimmed = _npmHtmlToText.default.fromString(postContent).trim();
      return trimmed !== '';
    }),

    isSubjectEdited: computed('editedCase.subject', 'model.subject', function () {
      return this.get('model.subject') !== this.get('editedCase.subject');
    }),

    isAssigneeEdited: computed('editedCase.assignedAgent', 'model.assignedAgent', 'editedCase.assignedTeam', 'model.assignedTeam', function () {
      return this.get('model.assignedAgent.content') !== this.get('editedCase.assignedAgent') || this.get('model.assignedTeam.content') !== this.get('editedCase.assignedTeam');
    }),

    isAssignedToMeBeforeEditing: computed('model.assignedAgent', function () {
      return this.get('model.assignedAgent.content.id') === this.get('session.user.id');
    }),

    isAssignedToMeAfterEdit: computed('editedCase.assignedAgent', 'session.user', function () {
      return this.get('editedCase.assignedAgent') === this.get('session.user');
    }),

    isCurrentUserInMultipleTeams: computed('session.user.teams.[]', function () {
      return this.get('session.user.teams.length') > 1;
    }),

    isStatusEdited: computed('editedCase.status', 'model.status', function () {
      return this.get('model.status.content') !== this.get('editedCase.status');
    }),

    isTypeEdited: computed('editedCase.caseType', 'model.caseType', function () {
      return this.get('model.caseType.content') !== this.get('editedCase.caseType');
    }),

    isPriorityEdited: computed('editedCase.priority', 'model.priority', function () {
      return this.get('model.priority.content') !== this.get('editedCase.priority');
    }),

    isTagsFieldEdited: computed('editedTags.@each.name', 'model.tags.@each.name', function () {
      var editedTags = this.get('editedTags').mapBy('name');
      var tags = this.get('model.tags').mapBy('name');
      return editedTags.get('length') !== tags.get('length') || tags.any(function (tag) {
        return !editedTags.includes(tag);
      });
    }),

    isFormEdited: computed('editedCase.form', 'model.form', function () {
      return this.get('model.form.content') !== this.get('editedCase.form');
    }),

    hasTimerValue: bool('timerValue'),

    arePropertiesOtherThanStatusEdited: or('isSubjectEdited', 'isAssigneeEdited', 'isTypeEdited', 'isPriorityEdited', 'isTagsFieldEdited', 'isFormEdited', 'customFields.isEdited'),

    arePropertiesEdited: or('arePropertiesOtherThanStatusEdited', 'isStatusEdited'),

    isOnlyStatusEdited: computed('arePropertiesOtherThanStatusEdited', 'isStatusEdited', function () {
      return !this.get('arePropertiesOtherThanStatusEdited') && this.get('isStatusEdited');
    }),

    isEdited: or('arePropertiesEdited', 'isContentEdited', 'hasTimerValue'),

    // We have to set this magic property, because after
    // save/restore stte operation, computed property will
    // stop updating.
    isEditedChanged: observer('isEdited', function () {
      scheduleOnce('sync', this, 'persistTabState');
    }),

    timelineForModel: computed('timeline', 'model', function () {
      return this.get('timeline').timelineForCase(this.get('model'));
    }),

    atMentionsSupported: computed('isNote', 'noteDestination', function () {
      var isNote = this.get('isNote');
      var destination = this.get('noteDestination.id') || 'case';
      var isConversationNote = isNote && destination === 'case';

      return isConversationNote;
    }),

    hasEnqueuedOperations: notEmpty('timelineForModel.sendingOperations'),

    isSaving: or('create.isRunning', 'update.isRunning', 'legacyReply.isRunning', 'performTrashCase.isRunning', 'restoreCase.isRunning', 'completeAndClose.isRunning', 'hasEnqueuedOperations'),

    initEdits: function initEdits() {
      var model = this.get('model');
      var editedCase = this.get('virtualModel').makeSnapshot(model, caseSchema);

      this.setProperties({
        editedCase: editedCase,
        errorMap: EmberObject.create(),
        attachedPostFiles: [],
        propertiesChangeViaKRE: EmberObject.create({
          customFields: EmberObject.create()
        }),
        inReplyTo: EmberObject.create({ id: null, uuid: null }),
        postContent: '',
        noteDestination: null,
        subject: this.get('subject'),
        customFields: _editedCustomFields.default.create({
          originalCustomFields: this.get('model.customFields'),
          editedCustomFields: editedCase.get('customFields')
        }),
        editedTags: this.get('model.tags').map(function (tag) {
          return EmberObject.create({
            name: tag.get('name'),
            isKREEdited: false,
            isNew: false,
            tagtype: tag.get('tagtype')
          });
        }),
        updateLog: [],
        isCCActive: false,
        requester: editedCase.get('requester')
      });
      this.set('replyOptions.cc', []);
    },
    resetReplyBox: function resetReplyBox() {
      this.setProperties({
        attachedPostFiles: [],
        inReplyTo: EmberObject.create({ id: null, uuid: null }),
        postContent: '',
        isCCActive: false,
        noteDestination: null
      });
      this.set('replyOptions.cc', []);
    },
    setParticipantsAsCCs: function setParticipantsAsCCs() {
      var _this2 = this;

      var caseId = this.get('model.id');
      if (caseId) {
        return this.get('store').adapterFor('case').getParticipants(caseId).then(function (emails) {
          _this2.setCCs(emails);
        });
      }
      return Promise.resolve();
    },
    resetSidebar: function resetSidebar() {
      var model = this.get('model');
      var editedCase = this.get('virtualModel').makeSnapshot(model, caseSchema);

      this.setProperties({
        editedCase: editedCase,
        errorMap: EmberObject.create(),
        propertiesChangeViaKRE: EmberObject.create({
          customFields: EmberObject.create()
        }),
        customFields: _editedCustomFields.default.create({
          originalCustomFields: this.get('model.customFields'),
          editedCustomFields: editedCase.get('customFields')
        }),
        editedTags: this.get('model.tags').map(function (tag) {
          return EmberObject.create({
            name: tag.get('name'),
            isKREEdited: false,
            isNew: false,
            tagtype: tag.get('tagtype')
          });
        }),
        updateLog: []
      });
    },
    persistTabState: function persistTabState() {
      this.get('tabStore').updateState(this.get('tab'), {
        case: assign(this.getProperties('subject', 'postContent', 'publicChannelId', 'isNote', 'inReplyTo', 'attachedPostFiles', 'replyOptions', 'timerValue', 'isBillable', 'noteDestination'), {
          requesterId: this.get('editedCase.requester.id'),
          _isEdited: this.get('isEdited')
        })
      });
    },
    loadPosts: function loadPosts(_ref) {
      var _this3 = this;

      var model = _ref.model,
          filter = _ref.filter,
          postId = _ref.postId;

      this.set('posts', []);
      this.set('topPostsAvailable', true);
      this.set('bottomPostsAvailable', Boolean(postId));

      return this.loadPostsAbove({ model: model, filter: filter, postId: postId || null, including: true }).catch(function (e) {
        // if we've failed to load posts because the postID refers to a post which doesn't exist
        // then just pretend we weren't given an ID and start from the top
        if (postId) {
          _this3.set('bottomPostsAvailable', false);
          return _this3.loadPostsAbove({ model: model, filter: filter, postId: null, including: true });
        } else {
          throw e;
        }
      });
    },
    loadPostsAbove: function loadPostsAbove(_ref2) {
      var model = _ref2.model,
          filter = _ref2.filter,
          postId = _ref2.postId,
          including = _ref2.including;

      return this.get('timeline').loadPostsAbove(this, { model: model, filter: filter, postId: postId, including: including });
    },
    loadPostsBelow: function loadPostsBelow(_ref3) {
      var model = _ref3.model,
          filter = _ref3.filter,
          postId = _ref3.postId;

      return this.get('timeline').loadPostsBelow(this, { model: model, filter: filter, postId: postId });
    },


    refreshTags: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee2(model) {
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return model.get('tags').reload();

            case 2:
              model.set('tags', model.get('tags').filterBy('isNew', false));

            case 3:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    })).enqueue(),

    refreshNotes: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee3() {
      var notes;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.prev = 0;
              _context3.next = 3;
              return this.get('store').query('note', {
                parent: this.get('model'),
                limit: 999
              });

            case 3:
              notes = _context3.sent;

              this.set('model.viewNotes', notes.toArray());
              this.get('store').pushPayload(notes);
              _context3.next = 11;
              break;

            case 8:
              _context3.prev = 8;
              _context3.t0 = _context3['catch'](0);

              if (!Ember.testing && window.Bugsnag) {
                window.Bugsnag.notifyException(_context3.t0, 'Failed to refresh notes', {}, 'info');
              }

            case 11:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this, [[0, 8]]);
    })).restartable(),

    completeAndClose: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee4(completedStatus) {
      var model, editedCase;
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              model = this.get('model');
              editedCase = this.get('virtualModel').makeSnapshot(model, closeSchema);

              editedCase.set('status', completedStatus);
              _context4.next = 5;
              return this.get('virtualModel').save(model, editedCase, closeSchema);

            case 5:
              this.initEdits();
              this.persistTabState();
              this.get('tabStore').close(this.get('tab'));

            case 8:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this);
    })).drop(),

    addAttachment: function addAttachment(attachment) {
      var attachments = cleanupAttachments(this.get('attachedPostFiles'));

      attachments.pushObject(attachment);
      this.set('attachedPostFiles', attachments);
      this.persistTabState();
    },
    cancelAttachment: function cancelAttachment(attachment) {
      var attachments = cleanupAttachments(this.get('attachedPostFiles'));

      attachments.removeObjects(attachments.filter(function (a) {
        return a === attachment;
      }));

      this.set('attachedPostFiles', attachments);
      this.persistTabState();
    },
    updateAttachments: function updateAttachments() {
      this.persistTabState();
    },
    setInReplyTo: function setInReplyTo(post) {
      this.set('inReplyTo', EmberObject.create({
        uuid: post.get('uuid'),
        id: post.get('id')
      }));
      this.updateTwitterType(post.get('original.postType'));
      this.persistTabState();
    },
    removeInReplyTo: function removeInReplyTo() {
      this.set('inReplyTo', null);
      this.persistTabState();
    },
    updateTwitterType: function updateTwitterType(postType) {
      var channel = this.get('channel');
      if (channel && channel.get('channelType') === 'TWITTER') {
        if (postType === 'twitterTweet') {
          // Twitter Public Message
          this.setTwitterType('REPLY');
        } else {
          // Twitter DM message
          this.setTwitterType('DM');
        }
      } else {
        this.setTwitterType(null);
      }
    },
    setChannel: function setChannel(channel) {
      this.set('publicChannelId', channel.get('id'));
      this.set('isNote', false);

      if (channel && channel.get('channelType') === 'FACEBOOK') {
        this.set('attachedPostFiles', []);
      }

      this.persistTabState();
    },
    setNote: function setNote() {
      this.set('isNote', true);
      this.persistTabState();
    },
    setTimerValue: function setTimerValue(totalSeconds, isBillable) {
      this.setProperties({
        timerValue: totalSeconds,
        isBillable: isBillable
      });
      this.persistTabState();
    },
    setCCs: function setCCs(emails) {
      var mailboxAddresses = this.get('store').peekAll('channel').filterBy('isChannelTypeMailbox').getEach('handle');
      var hasMailboxEmail = void 0;

      emails = emails.map(function (email) {
        if (typeOf(email) === 'string') {
          return email;
        } else {
          return email.get('identity.email');
        }
      }).filter(function (email) {
        var emailIsAMailboxAddress = mailboxAddresses.includes(email);
        if (emailIsAMailboxAddress) hasMailboxEmail = true;
        return !emailIsAMailboxAddress;
      }).uniq();

      if (hasMailboxEmail) {
        this.get('notification').add({
          type: 'error',
          title: this.get('i18n').t('cases.mailbox_not_allowed_in_cc_title'),
          body: this.get('i18n').t('cases.mailbox_not_allowed_in_cc_subtitle'),
          autodismiss: true
        });
      }

      this.set('replyOptions.cc', emails);

      this._updateCCState();
    },
    addCC: function addCC(emailOrIdentity) {
      var emailAddress = void 0;
      var replyEmail = this.get('inReplyToPost.original.email');
      if (typeof emailOrIdentity === 'string') {
        emailAddress = emailOrIdentity;
      } else {
        emailAddress = emailOrIdentity.get('email');
      }

      var isRequester = false;
      this.model.get('requester.emails').toArray().some(function (emailRecord) {
        if (emailRecord.get('email') === emailAddress) {
          isRequester = true;
          return true;
        }
      });

      var mailboxAddresses = this.get('store').peekAll('channel').filterBy('isChannelTypeMailbox').getEach('handle');
      var emailIsAMailboxAddress = mailboxAddresses.includes(emailAddress);

      if (emailIsAMailboxAddress) {
        this.get('notification').add({
          type: 'error',
          title: this.get('i18n').t('cases.mailbox_not_allowed_in_cc_title'),
          body: this.get('i18n').t('cases.mailbox_not_allowed_in_cc_subtitle'),
          autodismiss: true
        });
      }

      if (!isRequester && emailAddress !== replyEmail && !emailIsAMailboxAddress) {
        this.get('replyOptions.cc').addObject(emailAddress);
        this._updateCCState();
      }
    },
    _updateCCState: function _updateCCState() {
      var launchDarkly = this.get('launchDarkly');

      this.persistTabState();

      if (launchDarkly.get('ops-event-tracking')) {
        this.get('metrics').trackEvent({
          event: 'Case - Update CC recipients',
          category: 'Agent'
        });
      }
    },
    appendToUpdateLog: function appendToUpdateLog(user, date) {
      var updateLog = this.get('updateLog');
      if (user) {
        updateLog.unshiftObject(EmberObject.create({
          user: user,
          updatedAt: date
        }));
      }
    },
    resetUpdateLog: function resetUpdateLog() {
      this.set('updateLog', []);
    },


    loadMacrosLazily: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee5(model, user, macro) {
      return regeneratorRuntime.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              _context5.next = 2;
              return macro.reload();

            case 2:
              this.applyMacro(model, user, macro, true);

            case 3:
            case 'end':
              return _context5.stop();
          }
        }
      }, _callee5, this);
    })).restartable(),

    applyMacro: function applyMacro(model, user, macro, isLazy) {
      var _this4 = this;

      // Checking for agent to determine if the object for this ID is present in DS
      // Checking for isLazy to determine if function was called lazily and macro.get('agent') to determine if
      // macro exists because it seems that a specific macro once loaded, always has it's agent data present.
      if (!isLazy && !macro.get('agent')) {
        this.get('loadMacrosLazily').perform(model, user, macro);
        return;
      }
      var replyType = macro.get('replyType');

      if (replyType) {
        if (replyType === 'REPLY') {
          var channel = this.get('store').peekRecord('channel', this.get('publicChannelId'));
          this.setChannel(channel);
        } else {
          this.setNote();
          this.removeInReplyTo();
        }
      }

      var contentsToAdd = macro.get('replyContents');
      if (contentsToAdd) {
        this.appendPostContent((0, _sanitize.sanitize)(contentsToAdd, _serverHtmlContent.default));
      }

      var newStatus = macro.get('status');

      if (newStatus) {
        this.setStatus(newStatus);
      }

      var newPriority = macro.get('priority');

      if (newPriority) {
        this.setPriority(newPriority);
      }

      var priorityAction = macro.get('priorityAction');

      if (priorityAction) {
        var currentCase = this.get('editedCase');
        var newPriorityLevel = void 0;

        if (priorityAction === 'INCREASE_ONE_LEVEL') {
          newPriorityLevel = currentCase.get('priority.level') + 1;
        } else {
          newPriorityLevel = Math.max(1, currentCase.get('priority.level') - 1);
        }

        var _newPriority = this.get('store').peekAll('case-priority').filter(function (priority) {
          return priority.get('level') === newPriorityLevel;
        }).get('firstObject');

        if (_newPriority) {
          this.setPriority(_newPriority);
        }
      }

      var newType = macro.get('caseType');

      if (newType) {
        this.setType(newType);
      }

      var newAssignee = getAssigneeFromMacro(user, macro);

      if (newAssignee) {
        this.setAssignee.apply(this, _toConsumableArray(newAssignee));
      }

      macro.get('addTags').forEach(function (name) {
        _this4.addTag(model, { name: name });
      });

      macro.get('removeTags').forEach(function (name) {
        _this4.removeTag(_this4.get('tagService').getTagByName(name));
      });

      macro.get('actions').forEach(function (action) {
        var actionName = action.get('name');
        if (actionName === 'subject') {
          _this4.setSubject(action.get('value'));
        } else if (actionName === 'brand') {
          var brandId = action.get('value');
          var brand = _this4.get('store').peekRecord('brand', brandId);
          if (brand) {
            if (model.get('isNew')) {
              model.set('brand', brand);
            } else {
              _this4.get('setBrand').perform(brand).then(function () {
                return model.get('replyChannels').reload();
              });
            }
          }
        } else if (actionName === 'mailbox') {
          var mailboxId = action.get('value');
          var mailbox = _this4.get('store').peekAll('channel').find(function (channel) {
            return String(channel.get('account.id')) === String(mailboxId);
          });
          if (mailbox) {
            _this4.setChannel(mailbox);
          }
        } else if (actionName === 'clear_tags') {
          _this4.clearTags();
        } else if (actionName === 'change_tags') {
          var tags = action.get('value').split(',').map(function (tag) {
            return tag.trim();
          });
          _this4.setTags(model, tags);
        } else if (actionName.startsWith('customfield_')) {
          var customFieldId = actionName.split('_')[1];
          var caseField = _this4.get('store').peekRecord('case-field', customFieldId);
          if (caseField) {
            var value = action.get('value');
            if (caseField.get('fieldType') === 'YESNO') {
              value = value === 'true' ? 'yes' : 'no';
            } else if (caseField.get('fieldType') === 'CHECKBOX') {
              value = value.split(',').map(function (v) {
                return v.trim();
              }).join(',');
            }
            _this4.setCustomField(caseField, value);
          }
        }
      });

      this.persistTabState();

      this.get('macro').trackUsage(macro.get('id'));
    },
    addTag: function addTag(model, tag) {
      var tagName = get(tag, 'actualName') || get(tag, 'name');
      var editedTags = this.get('editedTags');
      if (editedTags.find(function (tag) {
        return tag.get('name') === tagName;
      })) {
        return;
      }
      var newTag = EmberObject.create({
        name: tagName,
        isKREEdited: false,
        isNew: !model.get('tags').find(function (tag) {
          return tag.get('name') === tagName;
        })
      });
      editedTags.pushObject(newTag);
      editedTags.forEach(function (tag) {
        return tag.set('isErrored', false);
      });
      this.set('editedTags', editedTags);
      this.set('errorMap.tags', false);
      this.set('propertiesChangeViaKRE.tags', false);
      this.persistTabState();
    },
    removeTag: function removeTag(tag) {
      var tags = this.get('editedTags').rejectBy('name', tag.get('name'));
      tags.forEach(function (tag) {
        return tag.set('isErrored', false);
      });
      this.set('editedTags', tags);
      this.set('errorMap.tags', false);
      this.set('propertiesChangeViaKRE.tags', false);
      this.persistTabState();
    },
    clearTags: function clearTags() {
      var editedTags = this.get('editedTags').reject(function (tag) {
        return tag.get('tagtype') !== 'SYSTEM';
      });
      this.set('editedTags', editedTags);
      this.set('errorMap.tags', false);
      this.set('propertiesChangeViaKRE.tags', false);
      this.persistTabState();
    },
    setTags: function setTags(model, tagNames) {
      var existingTags = this.get('editedTags');
      var systemTags = existingTags.filter(function (tag) {
        return tag.get('tagtype') === 'SYSTEM';
      });
      var newTags = tagNames.map(function (name) {
        return EmberObject.create({
          name: name,
          isKREEdited: false,
          isNew: !model.get('tags').find(function (tag) {
            return tag.get('name') === name;
          })
        });
      });
      var finalTags = [].concat(_toConsumableArray(systemTags), _toConsumableArray(newTags));
      this.set('editedTags', finalTags);
      this.set('errorMap.tags', false);
      this.set('propertiesChangeViaKRE.tags', false);
      this.persistTabState();
    },
    setPostContent: function setPostContent(newContent) {
      this.set('postContent', newContent);
      this.set('errorMap.contents', false);
      this.persistTabState();
    },
    appendPostContent: function appendPostContent(newContent) {
      var postContent = this.get('postContent');

      newContent = newContent.replace(/\n/g, '<br>');

      if (postContent.trim()) {
        this.setPostContent(postContent + '<br>' + newContent);
      } else {
        this.setPostContent(newContent);
      }
    },
    setSubject: function setSubject(subject) {
      this.set('subject', subject);
      this.set('editedCase.subject', subject);
      this.set('errorMap.subject', false);
      this.set('propertiesChangeViaKRE.subject', false);
      this.persistTabState();
    },
    setRequester: function setRequester(requester) {
      this.set('editedCase.requester', requester);
      this.set('errorMap.requester_id', false);
      this.set('propertiesChangeViaKRE.requester', false);
      this.persistTabState();
    },
    setAssignee: function setAssignee(team, agent) {
      this.set('editedCase.assignedAgent', agent);
      this.set('editedCase.assignedTeam', team);
      this.set('errorMap.assigned_agent_id', false);
      this.set('errorMap.assigned_team_id', false);
      this.set('propertiesChangeViaKRE.assignee', false);
      this.persistTabState();
    },
    setStatus: function setStatus(status) {
      this.set('editedCase.status', status);
      this.set('errorMap.status_id', false);
      this.set('propertiesChangeViaKRE.status', false);

      this.persistTabState();
    },
    setType: function setType(type) {
      this.set('editedCase.caseType', type);
      this.set('errorMap.type_id', false);
      this.set('propertiesChangeViaKRE.caseType', false);
      this.persistTabState();
    },
    setPriority: function setPriority(priority) {
      this.set('editedCase.priority', priority);
      this.set('errorMap.priority_id', false);
      this.set('propertiesChangeViaKRE.priority', false);
      this.persistTabState();
    },
    setForm: function setForm(form) {
      this.set('editedCase.form', form);
      this.set('errorMap.form_id', false);
      this.set('propertiesChangeViaKRE.form', false);
      this.persistTabState();
    },
    setTwitterType: function setTwitterType(type) {
      this.set('replyOptions.type', type);
      this.persistTabState();
    },
    setNoteDestination: function setNoteDestination(destination) {
      this.set('noteDestination', destination);
      this.persistTabState();
    },
    assignToMe: function assignToMe() {
      this.setAssignee(this.get('session.user.teams.firstObject'), this.get('session.user'));
    },
    assignToMeInTeam: function assignToMeInTeam(team) {
      this.setAssignee(team, this.get('session.user'));
    },
    toggleCC: function toggleCC(active) {
      this.set('isCCActive', active);
    },
    checkCCInput: function checkCCInput(text) {
      this.set('hasCCInput', Boolean(text.trim().length));
    },


    // Tasks
    trashCase: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee6() {
      return regeneratorRuntime.wrap(function _callee6$(_context6) {
        while (1) {
          switch (_context6.prev = _context6.next) {
            case 0:
              _context6.next = 2;
              return this.get('confirmation').confirm({
                intlConfirmationHeader: 'cases.confirm.trash_case_header',
                intlConfirmationBody: 'cases.confirm.trash_case',
                intlConfirmLabel: 'cases.confirm.trash_case_button'
              });

            case 2:
              _context6.next = 4;
              return this.get('performTrashCase').perform();

            case 4:
            case 'end':
              return _context6.stop();
          }
        }
      }, _callee6, this);
    })).drop(),

    performTrashCase: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee7() {
      var caseModel;
      return regeneratorRuntime.wrap(function _callee7$(_context7) {
        while (1) {
          switch (_context7.prev = _context7.next) {
            case 0:
              caseModel = this.get('model');
              _context7.next = 3;
              return this.get('apiAdapter').trashCase(caseModel.get('id'));

            case 3:
              _context7.next = 5;
              return caseModel.reload();

            case 5:
              this.initEdits();
              this.persistTabState();
              this.get('tabStore').close(this.get('tab'));
              this.get('notification').success(this.get('i18n').t('cases.trash.success_message'));

            case 9:
            case 'end':
              return _context7.stop();
          }
        }
      }, _callee7, this);
    })).drop(),

    mergeCase: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee8() {
      var caseModel, timeline;
      return regeneratorRuntime.wrap(function _callee8$(_context8) {
        while (1) {
          switch (_context8.prev = _context8.next) {
            case 0:
              caseModel = this.get('model');
              timeline = this.get('timeline').timelineForCase(caseModel);
              _context8.next = 4;
              return this.get('mergeConversation').confirm({
                requesterName: caseModel.get('requester.fullName'),
                currentCase: caseModel,
                selectedConversations: []
              });

            case 4:
              _context8.next = 6;
              return timeline.get('fetchNewerAfterReply').perform();

            case 6:
            case 'end':
              return _context8.stop();
          }
        }
      }, _callee8, this);
    })).drop(),

    setBrand: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee9(brand) {
      var caseModel, result;
      return regeneratorRuntime.wrap(function _callee9$(_context9) {
        while (1) {
          switch (_context9.prev = _context9.next) {
            case 0:
              caseModel = this.get('model');

              if (!(!caseModel || caseModel.get('isNew'))) {
                _context9.next = 3;
                break;
              }

              return _context9.abrupt('return');

            case 3:

              caseModel.set('brand', brand);
              _context9.next = 6;
              return caseModel.save({ adapterOptions: { setBrand: true } });

            case 6:
              result = _context9.sent;

              this.get('notification').success(this.get('i18n').t('cases.brand_changed', { brand: brand.get('name') }));
              return _context9.abrupt('return', result);

            case 9:
            case 'end':
              return _context9.stop();
          }
        }
      }, _callee9, this);
    })).drop(),

    restoreCase: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee10() {
      var caseModel;
      return regeneratorRuntime.wrap(function _callee10$(_context10) {
        while (1) {
          switch (_context10.prev = _context10.next) {
            case 0:
              caseModel = this.get('model');
              _context10.next = 3;
              return this.get('apiAdapter').restoreCase(caseModel.get('id'));

            case 3:
              _context10.next = 5;
              return caseModel.reload();

            case 5:
              this.get('notification').success(this.get('i18n').t('cases.trash.restore.success_message'));

            case 6:
            case 'end':
              return _context10.stop();
          }
        }
      }, _callee10, this);
    })).drop(),

    initChannels: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee11() {
      var channels, replyChannel;
      return regeneratorRuntime.wrap(function _callee11$(_context11) {
        while (1) {
          switch (_context11.prev = _context11.next) {
            case 0:
              if (!(this.get('model.isNew') && !this.get('editedCase.requester'))) {
                _context11.next = 2;
                break;
              }

              return _context11.abrupt('return');

            case 2:
              _context11.next = 4;
              return this.get('model.replyChannels');

            case 4:
              channels = _context11.sent;
              replyChannel = this.selectSuitableReplyChannel(channels, this.get('publicChannelId'));


              if (replyChannel) {
                this.set('publicChannelId', replyChannel.get('id'));
              } else {
                this.set('publicChannelId', null);
                this.set('isNote', true);
              }

              if (!this.get('replyOptions.type')) {
                if (replyChannel && replyChannel.get('channelType') === 'TWITTER') {
                  this.updateTwitterType('twitterTweet');
                } else {
                  this.updateTwitterType(null);
                }
              }

            case 8:
            case 'end':
              return _context11.stop();
          }
        }
      }, _callee11, this);
    })).drop(),

    selectSuitableReplyChannel: function selectSuitableReplyChannel(channels, publicChannelId) {
      var existing = channels.findBy('id', publicChannelId);
      if (existing) {
        return existing;
      }

      var lastPublicChannel = channels.findBy('id', this.get('model.lastPublicChannel.id'));
      if (lastPublicChannel) {
        return lastPublicChannel;
      }

      var defaultChannel = channels.findBy('account.isDefault');
      if (defaultChannel) {
        return defaultChannel;
      }

      // otherwise fall-back to the first non-NOTE channel
      var nonNoteChannel = channels.findBy('account');
      if (nonNoteChannel) {
        return nonNoteChannel;
      }

      return null;
    },
    setCustomField: function setCustomField(field, value) {
      if (value) {
        value = get(value, 'id') || value;
      }
      this.get('customFields').setValue(field, value);
      this.get('errorMap').set(field.get('key'), false);
      this.set('propertiesChangeViaKRE.customFields.' + field.get('id'), false);
      this.persistTabState();
    },


    create: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee12() {
      var _this5 = this;

      var launchDarkly, model, originalTags, uploads, attachmentIds, replyOptions, channel, post;
      return regeneratorRuntime.wrap(function _callee12$(_context12) {
        while (1) {
          switch (_context12.prev = _context12.next) {
            case 0:
              launchDarkly = this.get('launchDarkly');

              if (!this.isUploadInProgress()) {
                _context12.next = 4;
                break;
              }

              this.get('notification').add({
                type: 'warning',
                title: 'Upload in progress',
                autodismiss: true
              });
              return _context12.abrupt('return');

            case 4:
              model = this.get('model');
              originalTags = model.get('tags').map(function (tag) {
                return tag;
              });
              uploads = this.get('attachedPostFiles').filter(function (attachment) {
                return isEmpty(attachment.get('error'));
              });
              attachmentIds = uploads.mapBy('attachmentId').compact();
              replyOptions = this.get('replyOptions');
              channel = this.get('channel');
              post = formatPostForSending(this.get('postContent'), channel);


              model.set('tags', this.get('editedTags').map(function (tag) {
                return _this5.get('tagService').getTagByName(tag.get('name'));
              }));

              model.set('contents', post);
              model.set('channel', channel.get('channelType'));
              model.set('channelId', channel.get('account.id'));
              model.set('attachmentFileIds', attachmentIds);
              model.set('channelOptions', this.get('store').createFragment('case-reply-options', replyOptions));

              _context12.prev = 17;
              _context12.next = 20;
              return this.get('virtualModel').save(model, this.get('editedCase'), caseSchema);

            case 20:
              _context12.next = 22;
              return this.get('refreshTags').perform(model);

            case 22:
              this.initEdits();
              this.persistTabState();

              this.get('notification').add({
                type: 'success',
                title: this.get('i18n').t('cases.case.created'),
                autodismiss: true
              });

              if (launchDarkly.get('release-apps')) {
                this.trigger('updated');
              }
              _context12.next = 35;
              break;

            case 28:
              _context12.prev = 28;
              _context12.t0 = _context12['catch'](17);

              if (_context12.t0.errors[0].code === 'RATE_LIMIT_REACHED') {
                this.get('notification').add({
                  type: 'error',
                  title: this.get('i18n').t('cases.post-failed-rate-limit.label'),
                  autodismiss: true
                });
              }
              model.get('errors').clear();
              model.set('tags', originalTags);
              this.set('errorMap', convertErrorsToMap(_context12.t0.errors));

              throw _context12.t0;

            case 35:
            case 'end':
              return _context12.stop();
          }
        }
      }, _callee12, this, [[17, 28]]);
    })).drop(),

    // we are just updating the case -- don't create a case-reply
    update: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee13() {
      var _this6 = this;

      var launchDarkly, model, editedCase, editedTags, originalTags;
      return regeneratorRuntime.wrap(function _callee13$(_context13) {
        while (1) {
          switch (_context13.prev = _context13.next) {
            case 0:
              launchDarkly = this.get('launchDarkly');

              if (!this.isUploadInProgress()) {
                _context13.next = 4;
                break;
              }

              this.get('notification').add({
                type: 'warning',
                title: 'Upload in progress',
                autodismiss: true
              });
              return _context13.abrupt('return');

            case 4:
              model = this.get('model');
              editedCase = this.get('editedCase');
              editedTags = this.get('editedTags');
              originalTags = model.get('tags').toArray().slice(0);


              model.set('tags', editedTags.map(function (tag) {
                return _this6.get('tagService').getTagByName(tag.get('name'));
              }));
              _context13.prev = 9;
              _context13.next = 12;
              return this.get('virtualModel').save(model, editedCase, caseSchema);

            case 12:
              _context13.next = 14;
              return this.get('refreshTags').perform(model);

            case 14:
              this.initEdits();
              this.applyRemoteChanges(model, editedCase, editedTags);
              this.persistTabState();

              this.get('notification').add({
                type: 'success',
                title: this.get('i18n').t('cases.case.updated'),
                autodismiss: true
              });

              if (launchDarkly.get('release-apps')) {
                this.trigger('updated');
              }
              _context13.next = 27;
              break;

            case 21:
              _context13.prev = 21;
              _context13.t0 = _context13['catch'](9);

              model.get('errors').clear();
              model.set('tags', originalTags);
              this.set('errorMap', convertErrorsToMap(_context13.t0.errors));
              throw _context13.t0;

            case 27:
            case 'end':
              return _context13.stop();
          }
        }
      }, _callee13, this, [[9, 21]]);
    })).drop(),

    updateAndResend: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee14() {
      var propertyAttrs, operations;
      return regeneratorRuntime.wrap(function _callee14$(_context14) {
        while (1) {
          switch (_context14.prev = _context14.next) {
            case 0:
              propertyAttrs = this.gatherPropertyAttrs();
              operations = this.get('timelineForModel.sendingOperations');


              operations.forEach(function (op) {
                var oldAttrs = get(op, 'attrs');
                var newAttrs = assign({}, oldAttrs, propertyAttrs);
                set(op, 'state', OP_STATE_WAITING);
                set(op, 'attrs', newAttrs);
              });

              this.get('deliveryLoop').perform();

            case 4:
            case 'end':
              return _context14.stop();
          }
        }
      }, _callee14, this);
    })),

    legacyReply: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee15(timeline) {
      var _this7 = this;

      var launchDarkly, model, originalTags, uploads, attachmentIds, replyOptions, inReplyToUuid, channel, post, editedTags, editedCase, channelType, reply, caseReply;
      return regeneratorRuntime.wrap(function _callee15$(_context15) {
        while (1) {
          switch (_context15.prev = _context15.next) {
            case 0:
              launchDarkly = this.get('launchDarkly');

              if (!this.isUploadInProgress()) {
                _context15.next = 4;
                break;
              }

              this.get('notification').add({
                type: 'warning',
                title: 'Upload in progress',
                autodismiss: true
              });
              return _context15.abrupt('return');

            case 4:
              model = this.get('model');
              originalTags = model.get('tags').map(function (tag) {
                return tag;
              });
              uploads = this.get('attachedPostFiles').filter(function (attachment) {
                return isEmpty(attachment.get('error'));
              });
              attachmentIds = uploads.mapBy('attachmentId').compact();
              replyOptions = this.get('replyOptions');
              inReplyToUuid = this.get('inReplyTo.uuid');
              channel = this.get('channel');
              post = formatPostForSending(this.get('postContent'), channel);
              editedTags = this.get('editedTags');


              model.set('tags', editedTags.map(function (tag) {
                return _this7.get('tagService').getTagByName(tag.get('name'));
              }));
              editedCase = this.get('editedCase');
              channelType = channel.get('channelType');
              reply = this.get('store').createRecord('case-reply', {
                case: model,
                channel: channel.get('account'),
                assignedTeam: editedCase.get('assignedTeam'),
                assignedAgent: editedCase.get('assignedAgent'),
                channelType: channelType,
                contents: post,
                inReplyToUuid: inReplyToUuid,
                channelOptions: this.get('store').createFragment('case-reply-options', replyOptions),
                status: editedCase.get('status'),
                caseType: editedCase.get('caseType'),
                priority: editedCase.get('priority'),
                requester: editedCase.get('requester'),
                subject: editedCase.get('subject'),
                form: editedCase.get('form'),
                fieldValues: [],
                tags: editedTags.map(function (tag) {
                  return tag.get('name');
                }).join(','),
                attachmentFileIds: attachmentIds
              });


              editedCase.get('customFields').forEach(function (customField) {
                reply.get('fieldValues').createFragment({
                  fieldId: customField.get('field.id'),
                  value: customField.get('value')
                });
              });

              _context15.prev = 18;
              _context15.next = 21;
              return reply.save();

            case 21:
              caseReply = _context15.sent;

              timeline.clearLocalReadState();
              timeline.addSentPosts(caseReply.get('posts'));
              _context15.next = 26;
              return this.get('refreshTags').perform(model);

            case 26:
              this.initEdits();
              this.applyRemoteChanges(model, editedCase, editedTags);
              this.persistTabState();

              this.get('notification').add({
                type: 'success',
                title: this.get('i18n').t('cases.case.updated'),
                autodismiss: true
              });

              if (launchDarkly.get('release-apps')) {
                this.trigger('updated');
              }
              _context15.next = 39;
              break;

            case 33:
              _context15.prev = 33;
              _context15.t0 = _context15['catch'](18);

              model.get('errors').clear();
              model.set('tags', originalTags);
              this.set('errorMap', convertErrorsToMap(_context15.t0.errors));
              throw _context15.t0;

            case 39:
            case 'end':
              return _context15.stop();
          }
        }
      }, _callee15, this, [[18, 33]]);
    })).drop(),

    hasReply: function hasReply() {
      var attachments = this.get('attachedPostFiles').filter(function (attachment) {
        return isEmpty(attachment.get('error'));
      });
      var attachmentIds = attachments.mapBy('attachmentId').compact();
      return this.get('postContent').trim() !== '' || attachmentIds.length > 0;
    },
    isUploadInProgress: function isUploadInProgress() {
      // @TODO we need to do something better here, UI wise.
      var uploads = this.get('attachedPostFiles');
      return uploads.any(function (u) {
        return u.get('status') === 'PROGRESS';
      });
    },
    updateCaseFromKRE: function updateCaseFromKRE(payload) {
      if (this.get('isSaving')) {
        return;
      }
      this.get('reloadCase').perform(payload);
    },


    reloadCase: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee16(_ref4) {
      var changed_properties = _ref4.changed_properties;
      var launchDarkly, caseModel, original, originalTags;
      return regeneratorRuntime.wrap(function _callee16$(_context16) {
        while (1) {
          switch (_context16.prev = _context16.next) {
            case 0:
              launchDarkly = this.get('launchDarkly');
              caseModel = this.get('model');

              // if the case is still saving, bail out otherwise Ember Data triggers an error
              // this happens if we get a KRE event during an update

              if (!(caseModel.get('isReloading') || caseModel.get('isSaving'))) {
                _context16.next = 4;
                break;
              }

              return _context16.abrupt('return');

            case 4:
              original = this.get('virtualModel').makeSnapshot(caseModel, caseSchema);
              originalTags = caseModel.get('tags').toArray().slice(0);
              _context16.next = 8;
              return caseModel.reload();

            case 8:
              _context16.next = 10;
              return this.get('refreshTags').perform(caseModel);

            case 10:
              if (!(changed_properties.pinned_notes_count !== undefined)) {
                _context16.next = 14;
                break;
              }

              _context16.next = 13;
              return this.get('refreshNotes').perform();

            case 13:
              // Pinned notes updates to not be notified about
              caseModel.setProperties({ lastUpdatedBy: null });

            case 14:
              this.applyRemoteChanges(caseModel, original, originalTags);

              if (launchDarkly.get('release-apps')) {
                this.trigger('updated');
              }

            case 16:
            case 'end':
              return _context16.stop();
          }
        }
      }, _callee16, this);
    })).keepLatest(),

    applyRemoteChanges: function applyRemoteChanges(caseModel, original, originalTags) {
      var propertiesChangeViaKRE = this.get('propertiesChangeViaKRE');
      var editedCase = this.get('editedCase');
      var errorMap = this.get('errorMap');

      if (original.get('assignedTeam') !== caseModel.get('assignedTeam.content') || original.get('assignedAgent') !== caseModel.get('assignedAgent.content')) {
        editedCase.set('assignedTeam', caseModel.get('assignedTeam.content'));
        editedCase.set('assignedAgent', caseModel.get('assignedAgent.content'));
        errorMap.set('assigned_agent_id', false);
        errorMap.set('assigned_team_id', false);
        propertiesChangeViaKRE.set('assignee', true);
      }

      if (original.get('subject') !== caseModel.get('subject')) {
        editedCase.set('subject', caseModel.get('subject'));
        errorMap.set('subject', false);
        propertiesChangeViaKRE.set('subject', true);
      }

      var asyncProperties = ['requester', 'status', 'caseType', 'priority', 'form'];
      asyncProperties.forEach(function (property) {
        if (original.get(property) !== caseModel.get(property + '.content')) {
          editedCase.set(property, caseModel.get(property + '.content'));
          errorMap.set(property, false);
          propertiesChangeViaKRE.set(property, true);
        }
      });

      this.appendToUpdateLog(caseModel.get('lastUpdatedBy.content'), caseModel.get('updatedAt'));

      this.get('store').peekAll('case-field').forEach(function (field) {
        var fieldPredicate = function fieldPredicate(fieldValue) {
          return fieldValue.get('field.id') === field.get('id');
        };
        var originalField = original.get('customFields').find(fieldPredicate);
        var updatedField = caseModel.get('customFields').find(fieldPredicate);
        var userModifiedField = editedCase.get('customFields').find(fieldPredicate);

        var originalValue = originalField ? originalField.get('value') : undefined; // eslint-disable-line no-undefined
        var updatedValue = updatedField ? updatedField.get('value') : undefined; // eslint-disable-line no-undefined
        var userModifiedValue = userModifiedField ? userModifiedField.get('value') : undefined; // eslint-disable-line no-undefined

        if (originalValue !== updatedValue) {
          // if the missing value was replaced with an empty string or vice versa, we won't mark it
          // as changed via kre (given that the local value was also falsish)
          var isFalsish = function isFalsish(val) {
            return val === undefined || val === '';
          }; // eslint-disable-line no-undefined
          var sameish = _npmLodash.default.every([userModifiedValue, originalValue, updatedValue], isFalsish);
          if (!sameish) {
            propertiesChangeViaKRE.get('customFields').set(field.get('id'), true);
          }
          errorMap.set(field.get('key'), false);
          if (updatedField) {
            if (userModifiedField) {
              userModifiedField.set('value', updatedValue);
            } else {
              var value = updatedField.get('value');
              var newField = EmberObject.create({ field: field, value: value });
              editedCase.get('customFields').pushObject(newField);
            }
          } else {
            editedCase.get('customFields').removeObject(userModifiedField);
          }
        }
      });

      var editedTags = this.get('editedTags');

      var serverTagNames = caseModel.get('tags').map(function (tag) {
        return tag.get('name');
      });
      var originalTagNames = originalTags.map(function (tag) {
        return tag.get('name');
      });
      var tagsWereModified = serverTagNames.length !== originalTagNames.length || _npmLodash.default.difference(serverTagNames, originalTagNames).length > 0;

      if (tagsWereModified) {
        errorMap.set('tags', false);
        propertiesChangeViaKRE.set('tags', true);
      }

      // Tags aded by the server
      serverTagNames.forEach(function (tagName) {
        if (originalTagNames.indexOf(tagName) === -1) {
          var tag = editedTags.find(function (tag) {
            return tag.get('name') === tagName;
          });
          if (!tag) {
            tag = EmberObject.create({
              name: tagName
            });
            editedTags.pushObject(tag);
          }
          tag.set('isKREEdited', true);
          tag.set('isNew', false);
        }
      });

      // Tags removed by the server
      originalTagNames.forEach(function (tagName) {
        if (serverTagNames.indexOf(tagName) === -1) {
          var tag = editedTags.find(function (tag) {
            return tag.get('name') === tagName;
          });
          if (tag) {
            editedTags.removeObject(tag);
          }
        }
      });
      this.persistTabState();
    },


    enqueueReply: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee17(meta) {
      var timelineService, model, timeline, code, attrs, state, createdAt, op;
      return regeneratorRuntime.wrap(function _callee17$(_context17) {
        while (1) {
          switch (_context17.prev = _context17.next) {
            case 0:
              if (!this.isUploadInProgress()) {
                _context17.next = 3;
                break;
              }

              this.get('notification').add({
                type: 'warning',
                title: 'Upload in progress',
                autodismiss: true
              });
              return _context17.abrupt('return');

            case 3:
              timelineService = this.get('timeline');
              model = this.get('model');
              timeline = timelineService.timelineForCase(model);
              code = this.get('arePropertiesEdited') ? 'replyAndUpdate' : 'reply';
              attrs = this.gatherAttrsForOperation();
              state = OP_STATE_WAITING;
              createdAt = this.get('serverClock').getServerTime().toDate();
              op = { code: code, attrs: attrs, state: state, meta: meta, createdAt: createdAt };


              this.resetReplyBox();
              timeline.addSendingOperation(op);
              this.get('deliveryLoop').perform();

            case 14:
            case 'end':
              return _context17.stop();
          }
        }
      }, _callee17, this);
    })),

    resend: function resend(operation) {
      set(operation, 'state', OP_STATE_WAITING);
      this.get('deliveryLoop').perform();
    },


    deliveryLoop: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee18() {
      var launchDarkly, timelineService, model, timeline, op, state, attrs, code, _task;

      return regeneratorRuntime.wrap(function _callee18$(_context18) {
        while (1) {
          switch (_context18.prev = _context18.next) {
            case 0:
              launchDarkly = this.get('launchDarkly');
              timelineService = this.get('timeline');
              model = this.get('model');
              timeline = timelineService.timelineForCase(model);

            case 4:
              if (!true) {
                _context18.next = 44;
                break;
              }

              // Get first op in outbox
              op = timeline.get('sendingOperations.firstObject');

              // Guard no op

              if (op) {
                _context18.next = 8;
                break;
              }

              return _context18.abrupt('return');

            case 8:
              state = get(op, 'state');

              if (!(state !== OP_STATE_WAITING)) {
                _context18.next = 11;
                break;
              }

              return _context18.abrupt('return');

            case 11:

              set(op, 'state', OP_STATE_SENDING);

              attrs = get(op, 'attrs');
              code = get(op, 'code');
              _task = void 0;
              _context18.t0 = code;
              _context18.next = _context18.t0 === 'reply' ? 18 : _context18.t0 === 'replyAndUpdate' ? 20 : 22;
              break;

            case 18:
              _task = this.get('reply');
              return _context18.abrupt('break', 23);

            case 20:
              _task = this.get('replyAndUpdate');
              return _context18.abrupt('break', 23);

            case 22:
              throw new Error('Unknown op code: ' + code);

            case 23:
              if (!launchDarkly.get('ops-simulate-flaky-sends')) {
                _context18.next = 29;
                break;
              }

              _context18.next = 26;
              return (0, _emberConcurrency.timeout)(5000);

            case 26:
              if (!(Math.random() > 0.75)) {
                _context18.next = 29;
                break;
              }

              set(op, 'state', OP_STATE_FAILED);
              return _context18.abrupt('continue', 4);

            case 29:
              _context18.prev = 29;
              _context18.next = 32;
              return _task.perform(attrs);

            case 32:
              timeline.get('sendingOperations').shiftObject();
              _context18.next = 42;
              break;

            case 35:
              _context18.prev = 35;
              _context18.t1 = _context18['catch'](29);

              console.error(_context18.t1); /* eslint no-console: "off" */
              set(op, 'error', _context18.t1);
              set(op, 'state', OP_STATE_FAILED);
              timeline.notifySendingOperationUpdate(op);
              return _context18.abrupt('return');

            case 42:
              _context18.next = 4;
              break;

            case 44:
            case 'end':
              return _context18.stop();
          }
        }
      }, _callee18, this, [[29, 35]]);
    })).drop(),

    reply: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee19(attrs) {
      var launchDarkly, store, model, originalTags, reply, timeline;
      return regeneratorRuntime.wrap(function _callee19$(_context19) {
        while (1) {
          switch (_context19.prev = _context19.next) {
            case 0:
              launchDarkly = this.get('launchDarkly');
              store = this.get('store');
              model = this.get('model');
              originalTags = model.get('tags').map(function (tag) {
                return tag;
              });

              attrs = assign({}, attrs, {
                channelOptions: store.createFragment('case-reply-options', attrs.channelOptions),
                fieldValues: attrs.fieldValues.map(function (fieldValue) {
                  return store.createFragment('case-field-value', fieldValue);
                })
              });
              reply = store.createRecord('case-reply', attrs);
              timeline = this.get('timeline').timelineForCase(attrs.case);
              _context19.prev = 7;
              _context19.next = 10;
              return reply.save();

            case 10:
              timeline.addSentPosts(reply.get('posts'));
              _context19.next = 13;
              return this.get('refreshTags').perform(this.get('model'));

            case 13:
              _context19.next = 15;
              return this.setParticipantsAsCCs();

            case 15:
              this.applyRemoteChanges(attrs.case, this.get('editedCase'), this.get('editedTags'));
              this.resetSidebar();
              timeline.get('fetchNewerAfterReply').perform();
              if (launchDarkly.get('release-apps')) {
                this.trigger('updated');
              }
              _context19.next = 28;
              break;

            case 21:
              _context19.prev = 21;
              _context19.t0 = _context19['catch'](7);

              reply.deleteRecord();
              model.get('errors').clear();
              model.set('tags', originalTags);
              this.set('errorMap', convertErrorsToMap(_context19.t0.errors));
              throw _context19.t0;

            case 28:
            case 'end':
              return _context19.stop();
          }
        }
      }, _callee19, this, [[7, 21]]);
    })),

    replyAndUpdate: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee20(attrs) {
      return regeneratorRuntime.wrap(function _callee20$(_context20) {
        while (1) {
          switch (_context20.prev = _context20.next) {
            case 0:
              _context20.next = 2;
              return this.get('reply').perform(attrs);

            case 2:
            case 'end':
              return _context20.stop();
          }
        }
      }, _callee20, this);
    })),

    gatherAttrsForOperation: function gatherAttrsForOperation() {
      var messageAttrs = this.gatherMessageAttrs();
      var propertyAttrs = this.gatherPropertyAttrs();
      var result = assign({}, messageAttrs, propertyAttrs);

      return result;
    },
    gatherMessageAttrs: function gatherMessageAttrs() {
      var model = this.get('model');
      var channel = this.get('channel');
      var channelType = channel.get('channelType');
      var channelOptions = (0, _cloneDeep.default)(this.get('replyOptions'));
      var contents = formatPostForSending(this.get('postContent'), channel);
      var uploads = this.get('attachedPostFiles').filter(function (attachment) {
        return isEmpty(attachment.get('error'));
      });
      var attachmentFileIds = uploads.mapBy('attachmentId').compact();
      var inReplyToUuid = this.get('inReplyTo.uuid');
      var clientId = (0, _v.default)();

      if (this.get('atMentionsSupported')) {
        var mentions = (0, _atMentions.extractMentions)(contents);

        if (mentions.length) {
          var mentionsPayload = this._convertToMentionsPayload(mentions);
          mentionsPayload = this._dedupeMentionsPayload(mentionsPayload);
          channelOptions.set('mentions', mentionsPayload);
        }
      } else {
        contents = (0, _atMentions.replaceMentionsWithPlainText)(contents);
      }

      return {
        clientId: clientId,
        case: model,
        channel: channel.get('account'),
        channelType: channelType,
        channelOptions: channelOptions,
        inReplyToUuid: inReplyToUuid,
        contents: contents,
        attachmentFileIds: attachmentFileIds,
        attachments: uploads
      };
    },
    _convertToMentionsPayload: function _convertToMentionsPayload(mentions) {
      return mentions.map(function (_ref5) {
        var subjectId = _ref5.subjectId,
            type = _ref5.type;

        return { id: subjectId, type: type };
      });
    },
    _dedupeMentionsPayload: function _dedupeMentionsPayload(mentions) {
      var serializeAndDedupe = function serializeAndDedupe(m, _ref6) {
        var id = _ref6.id,
            type = _ref6.type;

        var key = id + '|' + type;

        if (!m.includes(key)) {
          m.push(key);
        }

        return m;
      };

      var deserialize = function deserialize(item) {
        var _item$split = item.split('|'),
            _item$split2 = _slicedToArray(_item$split, 2),
            id = _item$split2[0],
            type = _item$split2[1];

        return { id: id, type: type };
      };

      return mentions.reduce(serializeAndDedupe, []).map(deserialize);
    },
    gatherPropertyAttrs: function gatherPropertyAttrs() {
      var editedCase = this.get('editedCase');
      var editedTags = this.get('editedTags');
      var fieldValues = editedCase.get('customFields').map(function (field) {
        return {
          fieldId: field.get('field.id'),
          value: field.get('value')
        };
      });
      var tags = editedTags.mapBy('name').join(',');

      return {
        assignedTeam: editedCase.get('assignedTeam'),
        assignedAgent: editedCase.get('assignedAgent'),
        status: editedCase.get('status'),
        caseType: editedCase.get('caseType'),
        priority: editedCase.get('priority'),
        requester: editedCase.get('requester'),
        subject: editedCase.get('subject'),
        form: editedCase.get('form'),
        fieldValues: fieldValues,
        tags: tags
      };
    },
    inferStateFromLatestPosts: function inferStateFromLatestPosts(posts) {
      this._inferTwitterStateFromLatestPosts(posts);
    },
    _inferTwitterStateFromLatestPosts: function _inferTwitterStateFromLatestPosts(posts) {
      var launchDarkly = this.get('launchDarkly');

      if (!launchDarkly.get('release-infer-twitter-reply-type')) {
        return;
      }

      var latestMessage = [].concat(_toConsumableArray(posts)).reverse().findBy('original.isMessage');

      if (!latestMessage) {
        return;
      }

      var postType = latestMessage.get('original.postType');
      var channelType = this.get('channel.channelType');

      if (!postType) {
        return;
      }

      if (!postType.startsWith('twitter')) {
        return;
      }

      if (channelType !== 'TWITTER') {
        return;
      }

      this.updateTwitterType(postType);
    }
  });
});